<template>
  <div class="carousel-container">
    <div class="events__content container">
      <h2 class="section-title">Calendar of Events</h2>
      <div class="tab-buttons">
        <button
          :class="{'active-tab': activeTab === 'upcoming'}"
          @click="setActiveTab('upcoming')"
        >
          Upcoming
        </button>
        <button
          :class="{'active-tab': activeTab === 'past'}"
          @click="setActiveTab('past')"
        >
          Past
        </button>
      </div>

      <div v-if="activeTab === 'upcoming'">
        <div v-if="upcomingEvents.length === 0" class="no-events-message">
          Stay tuned for upcoming events!
        </div>
        <Carousel
          v-else
          v-bind="settings"
          :breakpoints="breakpoints"
          :mouse-drag="true"
          :loop="true"
          class="carousel-wrapper upcoming"
        >
          <Slide v-for="(event, index) in upcomingEvents" :key="index" class="slider-item">
            <div class="carousel-item">
              <a :href="event.url" target="_blank">
                <img v-if="event.image" :src="getImageUrl(event.image)" class="item-image" :alt="event.title">
              </a>
              <div class="item-content">
                <h3 class="item-title">{{ event.title }}</h3>
                <p class="item-date">{{ event.dateText }}</p>
                <a class="item-button" :href="event.url" target="_blank">{{ event.button }}</a>
              </div>
            </div>
          </Slide>
          <template #addons>
            <div class="carousel-navigation">
              <Navigation class="carousel-nav"/>
            </div>
          </template>
        </Carousel>
      </div>

      <div v-if="activeTab === 'past'">
        <div v-if="pastEvents.length === 0" class="no-events-message">
          Stay tuned for our past events recaps!
        </div>
        <Carousel
          v-else
          v-bind="settings"
          :breakpoints="breakpoints"
          :mouse-drag="true"
          :loop="true"
          class="carousel-wrapper past"
        >
          <Slide v-for="(event, index) in pastEvents" :key="index" class="slider-item">
            <div class="carousel-item">
              <a :href="event.url" target="_blank">
                <img v-if="event.image" :src="getImageUrl(event.image)" class="item-image" :alt="event.title">
              </a>
              <div class="item-content">
                <h3 class="item-title">{{ event.title }}</h3>
                <p class="item-date">{{ event.dateText }}</p>
                <a class="item-button" :href="event.url" target="_blank">{{ event.button }}</a>
              </div>
            </div>
          </Slide>
          <template #addons>
            <div class="carousel-navigation">
              <Navigation class="carousel-nav"/>
            </div>
          </template>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import eventData from './json/events.json';

const activeTab = ref('upcoming');
const setActiveTab = (tab) => (activeTab.value = tab);

const allEvents = eventData.map((item) => ({
  title: item.title,
  image: item.image,
  dateText: item.dateText,
  date: new Date(item.date),
  url: item.url,
  button: item.button,
}));

const upcomingEvents = computed(() =>
  allEvents.filter((event) => event.date >= new Date())
);
const pastEvents = computed(() =>
  allEvents.filter((event) => event.date < new Date())
);

const getImageUrl = (name) => {
  return require(`@/assets/events/carousel/${name}`);
};

const settings = {
  snapAlign: 'center',
  wrapAround: true,
};

const breakpoints = {
  500: { itemsToShow: 1, snapAlign: 'center' },
  767: { itemsToShow: 2, snapAlign: 'start' },
  1279: { itemsToShow: 3, snapAlign: 'start' },
  1400: { itemsToShow: 4, snapAlign: 'start' },
};
</script>

<style lang="scss" scoped>
@import "../../styles/import/variables.scss";
@import "../../styles/import/mixins.scss";


.events {
  &__content {
    max-width: 1600px;
  }
}
.section-title {
  color: $white;
  font-size: 40px;
  font-weight: 600;
  margin: 0 0 20px 0;
  line-height: 1.2;
  @include upto($breakpoint_lg) {
    font-size: 36px;
  }
  @include upto($breakpoint_sm) {
    font-size: 24px;
  }
}

.carousel-container {
  width: 100%;
  background-color: $alt-dark;
  padding: 60px 2rem;
  text-align: center;
}

.title {
  font-size: 24px;
  color: $white;
  margin-bottom: 1rem;
}

.tab-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  button {
    padding: 0.5rem 1rem;
    border: none;
    background-color: transparent;
    color: $gray;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
    transition: color 0.3s ease;

    &:not(:last-of-type) {
      margin-right: 1rem;
    }

    &.active-tab {
      color: $white;
      border-bottom: 2px solid $white;
      font-weight: 600;
    }
  }
}

.carousel-wrapper {
  padding: 1rem 0;
}

.slider-item {
  display: flex;
  justify-content: center;
  padding-right: 15px;
}

.slider-item:last-of-type {
  padding-right: 0;
}

.carousel-item {
  background-color: $dark-gray;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 400px;
  justify-content: space-between;
}

/*.upcoming {
  .carousel-item {
    @include between(1400px, 1458px) {
      min-height: 410px;
    }
    @include between(768px, 780px) {
      min-height: 395px;
    }
  }
}*/

.item-image {
  width: 100%;
  margin-bottom: 15px;
  border-radius: 10px;
}

.item-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.item-title {
  color: $white;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  line-height: 1.4;
  text-align: left;
  @include upto($breakpoint_md) {
    font-size: 16px;
  }
}

.item-date {
  color: $gray;
  font-size: 16px;
  margin: 10px 0 20px;
  font-weight: 300;
  line-height: 1.4;
  text-align: left;
  @include upto($breakpoint_md) {
    font-size: 14px;
  }
}

.item-button {
  padding: 10px;
  text-decoration: none;
  background-color: $white;
  color: $alt-dark-gray;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  @include transition(0.3s, ease, all);
  &:hover {
    background-color: $yellow;
  }
}

.no-events-message {
  font-size: 26px;
  color: $white;
  margin-top: 2rem;
}

.carousel-navigation {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

:deep(.carousel-nav) {
  display: flex;
  gap: 10px;
}

:deep(.carousel__prev),
:deep(.carousel__next) {
  position: relative;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
  font-size: 30px !important;
  background-color: $alt-dark;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
</style>