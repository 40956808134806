<template>
  <div class="hero">
    <div class="flex-container container">
      <div class="left-container">
        <h1>Welcome to DNA HOUSE</h1>
        <h3>Follow us around the world.</h3>
        <h5>DNA has presence at every major crypto event, year round.</h5>
        <div class="btn">
          <a href="#events">
            <button class="button">View Events</button>
          </a>
        </div>
      </div>
      <div class="right-container">
        <div class="photo-column first">
          <div class="photo-item horizontal">
            <img :src="getImageUrl(photos[0].src)" :alt="photos[0].alt" />
          </div>
          <div class="photo-item vertical">
            <img :src="getImageUrl(photos[1].src)" :alt="photos[1].alt" />
          </div>
        </div>
        <div class="photo-column">
          <div class="photo-item vertical">
            <img :src="getImageUrl(photos[2].src)" :alt="photos[2].alt" />
          </div>
          <div class="photo-item horizontal">
            <img :src="getImageUrl(photos[3].src)" :alt="photos[3].alt" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const getImageUrl = (name) => {
  return require(`@/assets/general/hero/${name}`);
};

const photos = [
  { src: '2.jpeg', alt: 'Photo 1' },
  { src: 'nashville.jpeg', alt: 'Photo 3' },
  { src: 'yacht.jpeg', alt: 'Photo 2' },
  { src: 'event.jpeg', alt: 'Photo 4' },
];
</script>

<style lang="scss">
@import "../../styles/import/variables.scss";
@import "../../styles/import/mixins.scss";

.hero {
  padding: 150px 0 100px;
  background-color: $alt-dark;
}

.flex-container {
  display: flex;
  gap: 80px;
  width: 100%;
  height: auto;
  @include upto ($breakpoint_md) {
    flex-direction: column;
    gap: 40px;
  }
}

.left-container {
  flex: .7;
}

.right-container {
  flex: 1;
}

.left-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1 {
    color: $white;
    font-size: 64px;
    font-weight: 500;
    margin: 0 0 25px 0;
    line-height: 1.2;
    @include upto($breakpoint_lg) {
      font-size: 54px;
    }
    @include upto($breakpoint_sm) {
      font-size: 36px;
    }
  }
  h3 {
    color: $white;
    font-size: 32px;
    font-weight: 400;
    margin: 0;
    line-height: 1.2;
    @include upto($breakpoint_lg) {
      font-size: 28px;
    }
    @include upto($breakpoint_sm) {
      font-size: 22px;
    }
  }
  h5 {
    color: $white;
    opacity: .7;
    font-size: 24px;
    font-weight: 300;
    margin: 10px 0 20px;
    line-height: 1.2;
    @include upto($breakpoint_lg) {
      font-size: 21px;
    }
    @include upto($breakpoint_sm) {
      font-size: 18px;
    }
  }
}

.right-container {
  display: flex;
  gap: 1rem;
}

.photo-column {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%; 
  &.first {
    position: relative;
    top: 65px;
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.4);
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100px;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgb(5 9 15) 100%);
      pointer-events: none;
    }
  }
}

.photo-item {
  overflow: hidden;
  border-radius: 0.5rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.photo-item.horizontal {
  height: 200px;
  @include upto ($breakpoint_md) {
    height: 100px;
  }
}

.photo-item.vertical {
  height: 400px;
  @include upto ($breakpoint_md) {
    height: 200px;
  }
}
</style>
