<template>
  <div class="upcoming" id="events">
    <div class="upcoming__container container">
      <h2 class="upcoming__title">
        Upcoming Events
      </h2>
      <p class="upcoming__description">
        Explore our lineup of upcoming events! From networking opportunities to inspiring talks, stay informed and make the most of what's ahead.
      </p>
      <div class="upcoming__countdown-container">
        <div class="timer-header">Next Event</div>
        <div class="timer">
          <div class="time-unit">
            <span class="time-value">{{ timeLeft.days }}</span>
            <span class="time-label">DAYS</span>
          </div>
          <div class="time-unit">
            <span class="time-value">{{ timeLeft.hours }}</span>
            <span class="time-label">HOURS</span>
          </div>
          <div class="time-unit">
            <span class="time-value">{{ timeLeft.minutes }}</span>
            <span class="time-label">MIN</span>
          </div>
        </div>
      </div>
      <div class="upcoming__events-container">
        <div v-for="(event, index) in events" :key="index" class="slider-item">
          <div class="carousel-item">
            <a :href="event.url" target="_blank">
              <img v-if="event.image" :src="getImageUrl(event.image)" class="item-image" :alt="event.title">
            </a>
            <div class="item-content">
              <h3 class="item-title">{{ event.title }}</h3>
              <p class="item-date">{{ event.dateText }}</p>
              <a class="item-button" :href="event.url" target="_blank">{{ event.button }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const eventDate = new Date("2025-02-18T09:01:00");

const timeLeft = ref({
  days: 0,
  hours: 0,
  minutes: 0,
});

const calculateTimeLeft = () => {
  const now = new Date();
  const difference = eventDate - now;

  if (difference > 0) {
    timeLeft.value.days = Math.floor(difference / (1000 * 60 * 60 * 24));
    timeLeft.value.hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    timeLeft.value.minutes = Math.floor((difference / (1000 * 60)) % 60);
  } else {
    timeLeft.value.days = 0;
    timeLeft.value.hours = 0;
    timeLeft.value.minutes = 0;
  }
};

let timer = null;

onMounted(() => {
  calculateTimeLeft();
  timer = setInterval(calculateTimeLeft, 1000);
});

onUnmounted(() => {
  if (timer) clearInterval(timer);
});

const getImageUrl = (name) => {
  return require(`@/assets/events/carousel/upcoming/${name}`);
};

const events = ref([
  {
    title: 'DNA at Consensus, Hong Kong',
    dateText: 'February 18 - 20, 2025, Hong Kong',
    image: 'hongkong.png', 
    url: 'https://lu.ma/9y5u29gp',
    button: 'Register Here',
    external: true
  },
  {
    title: 'DNA House at ETH Denver',
    dateText: 'February 27 - 28, 2025, Denver',
    image: 'denver-25.png', 
    url: 'https://lu.ma/z8b9db6p',
    button: 'Register Here',
    external: true
  },
]);
</script>

<style lang="scss" scoped>
@import "../../styles/import/variables.scss";
@import "../../styles/import/mixins.scss";

.upcoming {
  background-color: $alt-dark;
  padding: 100px 0 50px;
  @include upto ($breakpoint_md) {
    padding: 50px 0;
  }
  &__container {
    text-align: center;
  }
  &__title {
    color: $white;
    font-size: 40px;
    font-weight: 600;
    margin: 0 0 20px 0;
    line-height: 1.2;
    @include upto($breakpoint_lg) {
      font-size: 36px;
    }
    @include upto($breakpoint_sm) {
      font-size: 24px;
    }
  }
  &__description {
    color: $white;
    font-size: 18px;
    font-weight: 300;
    line-height: 26px;
    margin: 0 auto 15px;
    width: 70%;
    @include upto($breakpoint_sm) {
      font-size: 16px;
      line-height: 20px;
      width:100%;
    }
  }

  &__countdown-container {
    background-color: $dark-gray;
    padding: 10px 40px;
    border-radius: 0.5rem;
    display: flex;
    color: #fff;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin: 50px auto 40px;
    @include upto ($breakpoint_md) {
      flex-direction: column;
      width: 100%;
    }

    .timer-header {
      font-size: 18px;
      line-height: 26px;
      font-weight: 500;
      margin: 0 70px 0 0;
      color: $white;
      @include upto ($breakpoint_md) {
        margin: 0 0 10px 0;
      }
    }

    .timer {
      display: flex;
      gap: 50px;
      @include upto ($breakpoint_md) {
        gap: 35px;
      }
    }

    .time-unit {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .time-value {
      font-size: 32px;
      font-weight: 300;
      line-height: 1.2;
    }

    .time-label {
      font-size: 16px;
      font-weight: 400;
      margin-top: 0;
      color: #ffffff99;
    }
  }

  &__events-container {
    display: flex;
    justify-content: center;
    @include upto ($breakpoint_md) {
      flex-direction: column;
    }
    .slider-item {
      display: flex;
      justify-content: center;
      padding-right: 15px;
    }

    .slider-item:last-of-type {
      padding-right: 0;
    }

    .carousel-item {
      background-color: $dark-gray;
      padding: 15px;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      max-width: 340px;
      justify-content: space-between;
    }

    .item-image {
      width: 100%;
      margin-bottom: 15px;
      border-radius: 10px;
    }

    .item-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }

    .item-title {
      color: $white;
      font-size: 18px;
      font-weight: 500;
      margin: 0;
      line-height: 1.4;
      text-align: left;
      @include upto($breakpoint_md) {
        font-size: 16px;
      }
    }

    .item-date {
      color: $gray;
      font-size: 16px;
      margin: 10px 0 20px;
      font-weight: 300;
      line-height: 1.4;
      text-align: left;
      @include upto($breakpoint_md) {
        font-size: 14px;
      }
    }

    .item-button {
      padding: 10px;
      text-decoration: none;
      background-color: $yellow;
      color: $alt-dark-gray;
      border: none;
      border-radius: 6px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      @include transition(0.3s, ease, all);
      &:hover {
        background-color: $white;
      }
    }
  }
}
</style>